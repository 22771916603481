// Copyright © 2022 Vewd Software AS.
//
// This file is part of Vewd Cloud,
// and includes Vewd Confidential Information.
// Distribution is strictly prohibited without Vewd's written consent.

export default {
  sentry: {
    url: "https://6f37ba1f1ecf47aa8d414fc298f92f81@sentry.otvs.tv/567",
    release: "af6159f9b7496729ed9e570cbed299b77d4de889" || "local",
    environment: "production" || "local",
  },
};

const DEVELOPMENT_API_URLS = {
  API_GATEWAY:
    "https://cs-api-gateway.api-services-int.ci.k8s.tvint.qa/graphql",
  APP_DISTRIBUTION: "https://app-distribution.api-services-int.ci.k8s.tvint.qa",
  APP_INVENTORY: "https://app-inventory.api-services-int.ci.k8s.tvint.qa",
  AUTH_BAKER: "https://auth-baker.api-services-int.ci.k8s.tvint.qa",
  CONTENT_MGMT: "https://content-mgmt.api-services-int.ci.k8s.tvint.qa/admin",
  CONTENT_SUITE_API:
    "https://content-suite-api.api-services-int.ci.k8s.tvint.qa",
  DEVICE_MGMT: "https://device-mgmt.api-services-int.ci.k8s.tvint.qa",
  ELASTIC_SEARCH: "https://search-engine.api-services-int.ci.k8s.tvint.qa",
  JIRA: "https://bugs.corp.vewd.com",
  MESSAGE_CENTER: "https://message-center.api-services-int.ci.k8s.tvint.qa",
  MODERATION: "https://moderation.api-services-int.ci.k8s.tvint.qa",
  PROMOTIONS: "https://promotions.api-services-int.ci.k8s.tvint.qa",
  SNAP_API: "https://snapapi.api-services-int.ci.k8s.tvint.qa/api/v1",
  USER_CATALOGUE: "https://user-catalogue.api-services-int.ci.k8s.tvint.qa",
};

const PRODUCTION_API_URLS = {
  API_GATEWAY: "https://cs-api-gateway.cloud.vewd.com/graphql",
  APP_DISTRIBUTION: "https://app-distribution.cloud.vewd.com",
  APP_INVENTORY: "https://app-inventory.cloud.vewd.com",
  AUTH_BAKER: "https://auth-baker.cloud.vewd.com",
  CONTENT_MGMT: "https://content-mgmt.cloud.vewd.com/admin",
  CONTENT_SUITE_API: "https://content-suite-api.cloud.vewd.com",
  DEVICE_MGMT: "https://device-mgmt.cloud.vewd.com",
  ELASTIC_SEARCH: "https://search-engine.cloud.vewd.com",
  JIRA: "https://bugs.vewd.com",
  MESSAGE_CENTER: "https://message-center.cloud.vewd.com",
  MODERATION: "https://moderation.cloud.vewd.com",
  PROMOTIONS: "https://promotions.cloud.vewd.com",
  SNAP_API: "https://snapapi.cloud.vewd.com/api/v1",
  USER_CATALOGUE: "https://user-catalogue.cloud.vewd.com",
};

export const API_URLS =
  process.env.NODE_ENV === "production"
    ? PRODUCTION_API_URLS
    : DEVELOPMENT_API_URLS;
